import React from 'react'
// import { Redirect } from 'react-router-dom'
// import Box from '@mui/material/Box'
import * as mo from 'moment'
import { LinearProgress, Paper, Grid, Typography, TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import { withStyles } from '@mui/styles'
import { ArrowBack, Loop } from '@mui/icons-material'
import IconButton from '@mui/material/IconButton'
// import PostAddIcon from '@mui/icons-material/PostAdd'
import { ModalCust, TopBar, Snack, ExtLink } from './Lib'
// import { styleGuide } from './Style'
import ReactDiffViewer from 'react-diff-viewer'
import __ from '../util'

class RunsView extends React.Component {
  constructor (props) {
    super(props)
    this.cx = props.cx
    this.widthType = props.width
    this.reset = {
      loading: false,
      snack: undefined,
      runs: [],
      url: undefined,
      showHtmlDiff: false
    }
    this.state = {
      ...this.reset
    }
    this.getRuns = this.getRuns.bind(this)
    this.handleGoBack = () => {
      this.props.history.push('/dash')
    }
    this.handleShowDiff = async (diffStart, diffEnd) => {

      const [htmlStart, htmlEnd] = await Promise.all([
        this.cx.dash.getHtml(diffStart),
        this.cx.dash.getHtml(diffEnd)
      ])
      this.setState({
        showHtmlDiff: !this.state.showHtmlDiff,
        diffStart,
        diffEnd,
        htmlStart,
        htmlEnd
      })
    }
  }

  async getRuns () {
    this.setState({loading: true})
    try {
      const jobs = __.getJsonSto('codemonitor-jobs')
      const runs = await this.cx.dash.getRuns({
        email: __.getJsonSto('core').loginemail, //TODO validate
        url: jobs[this.props.id].url,
        ts: 1
      })
      this.setState({runs, url: jobs[this.props.id].url, loading: false})

    } catch (e) {
      if (__.cfg('isDev')) throw e
      const emsg = e.message
      __.clearSto()
      this.setState({ err: emsg })
      throw e
    }
  }

  async componentDidMount () {

    Object.assign(this, __.initView(this, 'dash'))

    await Promise.all([
      this.getRuns()
    ])
  }

  render () {
    if (this.state.err) {
      return (
        <ModalCust
          actions={[
            {
              lbl: 'Go to login',
              onClick: () => this.props.history.push('/login')
            }
          ]}
        >
          {this.state.err}
        </ModalCust>
      )
    } else if (this.state.loading) {
      return <LinearProgress />
    } else if (this.state.showHtmlDiff) {
      return (
        <ModalCust
          lbl={`HTML Diff ${mo(this.state.diffStart).format(__.cfg('tsDateFormat'))} - ${mo(this.state.diffStart).format(__.cfg('tsDateFormat'))}`}
          fullScreen
          onClose={() => this.setState({showHtmlDiff: !this.state.showHtmlDiff})}
          
        >
            <ReactDiffViewer oldValue={this.state.htmlStart} newValue={this.state.htmlEnd} splitView={true} />
          </ModalCust>
      )
    } else {
      return <div>
        {this.state.snack && (
          <Snack
            msg={this.state.snack}
            onClose={() => this.setState({ snack: undefined })}
          />
        )}
        <TopBar 
          midTitle={__.shortEl(this.state.url, 20)}
          iconLeft={<ArrowBack />}
          onClickLeft={this.handleGoBack}
          noDash
          noUser      
          noUpdate       
        />
        {this.state.runs && this.state.runs.length === 0 &&  <Grid container spacing={1} justifyContent='center' style={{paddingTop: 20}}>
          <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
              <Paper
              // className={this.props.classes.paperStyle}
              // elevation={10}
              style={{padding: 15, marginBottom: 20}}
              >
                <Typography variant='body1' center>
                  empty...
                    <IconButton
                      aria-label='Menu'
                      color='inherit'
                      onClick={async event => this.getRuns()}
                    >
                      <Loop />
                    </IconButton>
                </Typography>
              </Paper>
          </Grid>
        </Grid>}
        {this.state.runs && this.state.runs.length > 0 && <Grid container spacing={1} justifyContent='center' style={{paddingTop: 20}}>
          {this.state.runs.map((run, runIndex) => (
            <Grid item xs={12} sm={8} md={6} lg={4} xl={4} key={runIndex}>
              <Paper
              // className={this.props.classes.paperStyle}
              // elevation={10}
              style={{padding: 15, marginBottom: 20}}
              >
                <ExtLink to={`//cdn.codemonitor.net/screens/main/${run.ts}.jpg`} txt={<img src={`//cdn.codemonitor.net/screens/main/${run.ts}.jpg`} style={{maxWidth: '100%', maxHeight: '400px'}} />} />
                <Typography variant='body1'>
                  Domain: {run.domain}
                </Typography>
                <Typography variant='body1'>
                  Url: {run.url}
                </Typography>
                <Typography variant='body1'>
                  Created: {mo(run.ts, 'X').format(__.cfg('tsDateFormat'))}
                </Typography>
                <Typography variant='body1'>
                  Page title: {run.pageTitle}
                </Typography>
                <Typography variant='body1'>
                  Meta description: {run.metaDescription}
                </Typography>
                <Typography variant='body1'>
                  Robots Meta: {run.robotsMetaContent}
                </Typography>
                <Typography variant='body1'>
                  Links internal: {run.links.internal.join('\n')}
                </Typography>
                <Typography variant='body1'>
                  Links external: {run.links.external.join('\n')}
                </Typography>
                <Typography variant='body1'>
                  Img alt tags: {run.imageAltTags.toString()}
                </Typography>
                <Typography variant='body1'>
                  Headlines: {JSON.stringify(run.headlines)}
                </Typography>

                <Typography variant='body2'>
                  Tracker ? (auto loading - no consent)
                  google fonts ? (auto loading - no consent)
                </Typography>

                <Button
                  fullWidth
                  // variant='contained'
                  color='primary'
                  // className={this.props.classes.loginButton}
                  onClick={async event => this.handleShowDiff(run.lastRun, run.ts)}
                  disabled={!run.lastRun ? true : false}
                >
                  Show Html diff
                </Button>
              </Paper>
            </Grid>
            ))}
        </Grid>}
      </div>
    }
  }
}

export default withStyles({
    // colorFormat
  })(RunsView)
