import { createTheme } from '@mui/material/styles'
import common from '@mui/material/colors/common'

const styleGuide = {
  backgroundDark: '#121212',
  text: '#121212',
  textSecondary: '#757575',
  spacertop: '80px'
}

const theme = createTheme({
  palette: {
    // mode: 'dark',
    primary: {
      main: '#B4BDFF',
      dark: '#83A2FF'
    },
    secondary: {
      main: '#FFE3BB',
      dark: '#FFD28F'
    }
  },
  typography: {
    h1: {
      fontSize: '4rem',
      fontWeight: 900,
      letterSpacing: '1.5px'
    },
    h2: {
      fontSize: '3rem',
      fontWeight: 900,
      letterSpacing: '1.5px'
    },
    h3: {
      fontSize: '3rem',
      fontWeight: 900,
      letterSpacing: '1.5px'
    },
    h4: {
      fontSize: '2.5rem',
      fontWeight: 900,
      letterSpacing: '1.5px'
    },
    h5: {
      fontSize: '2.2rem',
      fontWeight: 900,
      letterSpacing: '1.5px'
    },
    h6: {
      fontSize: '2rem',
      fontWeight: 900,
      letterSpacing: '1.5px'
    }
  }
})

theme.components = {
  MuiButton: {
    styleOverrides: {
      containedPrimary: {
        background: 'linear-gradient(45deg, #B4BDFF 80%, #FFE3BB 20%)',
        borderRadius: 3,
        border: 0,
        color: 'white',
        height: 48,
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgb(180, 189, 255, .3)'
      }
    }
  },
  MuiInput: {
    underline: {
      '&:after': {
        borderBottom: `2px solid #1D2D2E`,
      }
    }
  },
  MuiFormLabel: {
    root: {
      '&$focused': {
        color: '#1D2D2E'
      }
    }
  }
}

const loginStyle = {
  paddingTop: styleGuide.spacertop,
  paddingBottom: theme.spacing(2)
}

const paperStyle = {
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2)
}

const dividerStyle = {
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(4)
}

const noTxtDeco = {
  textDecoration: 'none'
}

const extLink = {
  color: styleGuide.text
}

const fullWidth = {
  width: '100%'
}

const gridWrapPaper = {
  margin: '24px'
}

const gridItem = {
  marginBottom: theme.spacing(),
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
  padding: theme.spacing(3),
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  }
}

const fabProgress = {
  position: 'absolute',
  top: -6,
  left: -6,
  zIndex: 1
}

const gridSpacer = {
  paddingTop: theme.spacing(),
  paddingBottom: theme.spacing()
}

const loginButton = {
  width: '100%',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing()
}


const lockIcon = {
  width: theme.spacing(2),
  height: theme.spacing(2)
}

const colorFormat = {
  display: 'block',
  height: '100%',
  textAlign: 'center'
}

const gridWrap = {
  flewGrow: 1
}


export {
  styleGuide,
  theme,
  loginStyle,
  paperStyle,
  dividerStyle,
  noTxtDeco,
  extLink,
  fullWidth,
  gridWrapPaper,
  gridItem,
  gridSpacer,
  fabProgress,
  loginButton,
  lockIcon,
  colorFormat,
  gridWrap
}
