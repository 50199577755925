// import * as mo from 'moment'
import { StoBase } from './Lib'
import * as mo from 'moment'
import __ from '../util'

export default class Dash extends StoBase {
  constructor (cx, token) {
    super('Dash', cx, '766cb205-3697-417e-bcff-25d1ed7157c2')
    this.token = token
    this.postJob = this.postJob.bind(this)
    this.getJobs = this.getJobs.bind(this)
    this.getRuns = this.getRuns.bind(this)
    this.runJob = this.runJob.bind(this) 
    this.getHtml = this.getHtml.bind(this)
    this.delJob = this.delJob.bind(this)
  }

  async postJob (params) {
    const rqst = this.rqst
    const token = this.token

    const pld = await rqst(
      {
        url: 'job',
        method: 'POST',
        data: { ...params, token }
      },
      token
    )
    return pld
  }

  async delJob (params) {
    const rqst = this.rqst
    const token = this.token

    const pld = await rqst(
      {
        url: 'job',
        method: 'DELETE',
        data: { ...params, token }
      },
      token
    )
    return pld
  }

  async getJobs (params) {
    const rqst = this.rqst
    const token = this.token

    const pld = await rqst(
      {
        url: 'jobs',
        method: 'POST',
        data: { ...params, token }
      },
      token
    )
    return pld
  }
  
  async runJob (job) {
    const rqst = this.rqst
    const token = this.token

    const pld = await rqst(
      {
        url: 'run',
        method: 'POST',
        data: { ...job, token }
      },
      token
    )
    return pld
  }

  async getRuns (params) {
    const rqst = this.rqst
    const token = this.token

    const pld = await rqst(
      {
        url: 'runs',
        method: 'POST',
        data: { ...params, token }
      },
      token
    )
    return pld
  }

  async getHtml (ts) {
    const pld = await __.rqst(
      {
        url: `//cdn.codemonitor.net/html/main/${ts}.html`,
        method: 'GET'
      }
    )
    return pld
  }

}
