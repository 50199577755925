import React from 'react'
import { Link } from 'react-router-dom'
import MenuItem from '@mui/material/MenuItem'
import AppBar from '@mui/material/AppBar'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Input from '@mui/material/Input'
import Divider from '@mui/material/Divider'
import Modal from '@mui/material/Modal'
import InputLabel from '@mui/material/InputLabel'
import Toolbar from '@mui/material/Toolbar'
// import CameraIcon from '@mui/icons-material/Camera'
// import ScheduleIcon from '@mui/icons-material/Schedule'
import PostAddIcon from '@mui/icons-material/PostAdd'
// import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import LinearProgress from '@mui/material/LinearProgress'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import RttIcon from '@mui/icons-material/Rtt'
import {
  Close,
  Print,
  OpenInNew,
  CheckCircle,
  Loop
} from '@mui/icons-material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import FormHelperText from '@mui/material/FormHelperText'
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify'
import { theme } from './Style'
import __ from '../util'

const TopBar = ({
  title,
  midTitle,
  action,
  onClick,
  iconLeft,
  onClickLeft,
  color,
  noUpdate,
  noUser,
  noDash,
  modeCancel,
  isActionAllowed = true,
  group
}) => (
  <AppBar
    position='static'
    color={color || 'primary'}
    // elevation={1}
    style={{ zIndex: 1200 }}
  >
    <Toolbar style={{ minHeight: '50px' }}>
      <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
        <div style={{ marginRight: 'auto' }}>
          {modeCancel && (
            <Typography
              variant='h5'
              color='inherit'
              onClick={onClickLeft}
              style={{
                fontSize: '16px',
                cursor: 'pointer'
              }}
            >
              Cancel
            </Typography>
          )}
          {iconLeft && (
            <IconButton
              aria-label='Menu'
              onClick={onClickLeft}
              color='inherit'
              style={{
                right: theme.spacing(2),
                marginRight: 'auto'
              }}
            >
              {iconLeft}
            </IconButton>
          )}
          {title && (
            <Typography
              variant='h5'
              color='inherit'
              style={{ lineHeight: '27px' }}
            >
              <span role="img" aria-label="screen">🖥</span>
              &nbsp;
              <span>Code Monitor</span>
            </Typography>
          )}
        </div>
      </div>
      <div>
        <Typography
          variant='h5'
          color='inherit'
          align='center'
          style={{
            flex: 1,
            fontSize: '16px',
            fontWeight: '700'
          }}
        >
          {midTitle || ''}
        </Typography>
      </div>
      <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
        <div style={{ marginLeft: 'auto' }}>
          {action && (
            <Typography
              variant='h5'
              color='inherit'
              onClick={onClick}
              style={{
                fontSize: '16px',
                cursor: isActionAllowed ? 'pointer' : 'not-allowed',
                opacity: isActionAllowed ? 1 : 0.5
              }}
            >
              {action}
            </Typography>
          )}
          {!noDash && (
            <Link to='/dash' style={{ color: 'white' }}>
              <IconButton
                aria-label='Menu'
                color='inherit'
                style={{
                  left: theme.spacing(2)
                }}
              >
                <PostAddIcon />
              </IconButton>
            </Link>
          )}
          {!noUpdate && (
            <Link to='/' style={{ color: 'white' }}>
              <IconButton
                aria-label='Menu'
                color='inherit'
                style={{
                  left: theme.spacing(2)
                }}
              >
                <Loop />
              </IconButton>
            </Link>
          )}
          {!noUser && (
            <Link to='/user' style={{ color: 'white' }}>
              <IconButton
                aria-label='Menu'
                color='inherit'
                style={{
                  left: theme.spacing(2)
                }}
              >
                <PersonOutlineIcon />
              </IconButton>
            </Link>
          )}
        </div>
      </div>
    </Toolbar>
  </AppBar>
)

const Snack = ({ msg, onClose }) => (
  <Snackbar
    open
    autoHideDuration={4000}
    transitionDuration={800}
    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    onClose={onClose}
    ContentProps={{ 'aria-describedby': 'message-id' }}
    message={<span id='message-id'>{msg}</span>}
    action={[
      <IconButton
        key='close'
        aria-label='Close'
        color='inherit'
        onClick={onClose}
      >
        <Close />
      </IconButton>
    ]}
  />
)

const ExtLink = ({ to, txt, className, style }) => (
  <a
    href={to}
    target='_blank'
    rel='noopener noreferrer'
    className={className}
    style={style}
  >
    {txt}
  </a>
)

const TableColorFormaterPercent = ({ className, colorValue, text }) => {
  let cl = 'red'
  if (colorValue > 70) {
    cl = '#9CC17E'
  } else if (colorValue > 50) {
    cl = '#BDD7A9'
  } else if (colorValue > 20) {
    cl = '#A8C3F1'
  } else if (colorValue > 0.1) {
    cl = '#faedca'
  } else {
    cl = '#EBCFCC'
  }
  return (
    <span className={className} style={{ background: cl }}>
      {text}
    </span>
  )
}

const TableColorFormaterMargin = ({ className, colorValue, text }) => {
  return (
    <span
      className={className}
      style={{ background: __.colorValTable(colorValue) }}
    >
      {text}
    </span>
  )
}

const ExtBtn = () => <OpenInNew color='action' style={{ fontSize: 18 }} />

const CheckBtn = () => <CheckCircle color='action' style={{ fontSize: 18 }} />

class ModalCust extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
    this.children = props.children
    this.open = props.open == null ? true : props.open
    this.onClose = props.onClose
    this.onPrint = props.onPrint
    this.lbl = props.lbl || 'Error'
    this.fullScreen = props.fullScreen || false
    this.fullScreenNoCncl = props.fullScreenNoCncl || false
    this.showPrint = props.showPrint || false
    this.simple = props.simple || false
    const acs = props.actions || [{ lbl: 'OK', onClick: this.onClose }]
    if (!props.noCncl && this.lbl.toLowerCase() !== 'error') {
      acs.push({ lbl: 'Cancel', onClick: this.onClose })
    }
    this.btns = []
    for (const ac of acs) {
      this.btns.push(
        <Button
          key={ac.key || __.uuid()}
          onClick={
            props.withBusy
              ? async (...args) => {
                this.setState({ busy: true })
                const d = await ac.onClick(...args)
                return d
              }
              : ac.onClick
          }
        >
          {ac.lbl}
        </Button>
      )
    }
  }

  render () {
    if (this.fullScreen) {
      return (
        <Dialog
          fullScreen
          open={this.open}
          onClose={this.onClose}
          style={{ background: theme.palette.background.default }}
        >
          <AppBar style={{ position: 'relative' }}>
            <Toolbar>
              {!this.fullScreenNoCncl && <IconButton
                color='inherit'
                onClick={this.onClose}
                aria-label='Close'
              >
                <Close />
              </IconButton>}
              <Typography variant='h6' color='inherit' style={{ flex: 1 }}>
                {this.lbl}
              </Typography>
              {this.showPrint && <IconButton
                color='inherit'
                onClick={this.onPrint}
                aria-label='Print'
              >
                <Print />
              </IconButton>}
            </Toolbar>
          </AppBar>
          {this.children}
          {this.state.busy && <LinearProgress />}
        </Dialog>
      )
    } else if (this.simple) {
      return (
        <Dialog
          open={this.open}
          onClose={this.onClose}
          style={{ background: theme.palette.background.default }}
        >
          <DialogTitle>{this.lbl}</DialogTitle>
          <DialogContent>{this.children}</DialogContent>
          {!this.state.busy && this.btns && (
            <DialogActions>{this.btns}</DialogActions>
          )}
          {this.state.busy && <LinearProgress />}
        </Dialog>
      )
    } else {
      return (
        <Dialog
          open={this.open}
          onClose={this.onClose}
          style={{ background: theme.palette.background.default }}
        >
          <DialogTitle>{this.lbl}</DialogTitle>
          <DialogContent>
            <DialogContentText>{this.children}</DialogContentText>
          </DialogContent>
          {!this.state.busy && this.btns && (
            <DialogActions>{this.btns}</DialogActions>
          )}
          {this.state.busy && <LinearProgress />}
        </Dialog>
      )
    }
  }
}

// TODO umbauen auf POPOVER https://material-ui.com/components/popover/
const ModalOver = ({ children, open = false, handleOnClose }) => (
  <Modal open={open} onClose={handleOnClose}>
    <div
      style={{
        position: 'absolute',
        width: '90%',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2),
        top: `${50}%`,
        left: `${50}%`,
        transform: `translate(-${50}%, -${50}%)`,
        outline: 'none',
        overflow: 'auto',
        maxHeight: '90%'
      }}
    >
      {children}
    </div>
  </Modal>
)

class DropDown extends React.Component {
  // usage:
  //   <DropDown
  //     _id=<unique_id>
  //     data={this.state.<array_with_objs>}
  //     slctd={this.state.<label_of_selected_item}
  //     action={this.<action_function>}
  //     disabled={this.state.<disabled>}
  //     error={this.state.<inputError>}
  //     errorMsg={this.state.<inputErrorMsg>}
  //   />
  constructor (props) {
    super(props)
    this._id = props._id
    this.data = props.data
    this.title = props.title
    this.action = props.action
    this.renderValue = props.renderValue || (value => value)
    this.state = {
      slctd: props.slctd,
      disabled: props.disabled,
      error: props.error,
      errorMsg: props.errorMsg
    }
    this.handleChange = name => event => {
      this.setState({ [name]: event.target.value })
      this.action(
        this.data.find(i => {
          return i.lbl === event.target.value
        })
      )
    }
  }

  static getDerivedStateFromProps (props, state) {
    return {
      slctd: props.slctd,
      disabled: props.disabled,
      error: props.error,
      errorMsg: props.errorMsg
    }
  }

  render () {
    return (
      <FormControl fullWidth margin='normal'>
        <InputLabel htmlFor={this._id}>{this.title}</InputLabel>
        <Select
          value={this.state.slctd}
          onChange={this.handleChange('slctd')}
          input={<Input id={this._id} />}
          disabled={this.state.disabled}
          renderValue={this.renderValue}
          error={this.state.error}
        >
          {this.data.map(d => (
            <MenuItem key={d.key} value={d.lbl}>
              {d.lbl}
            </MenuItem>
          ))}
        </Select>
        {this.state.error && this.state.errorMsg && (
          <FormHelperText>{this.state.errorMsg}</FormHelperText>
        )}
      </FormControl>
    )
  }
}

export {
  TopBar,
  Snack,
  ModalCust,
  ModalOver,
  DropDown,
  ExtLink,
  ExtBtn,
  CheckBtn,
  TableColorFormaterPercent,
  TableColorFormaterMargin
}
