import React from 'react'
import { Link } from 'react-router-dom'
// import { Redirect } from 'react-router-dom'
// import Box from '@mui/material/Box'
import * as mo from 'moment'
import { LinearProgress, Paper, Grid, Typography, TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import { withStyles } from '@mui/styles'
import PostAddIcon from '@mui/icons-material/PostAdd'
import { ModalCust, TopBar, Snack } from './Lib'
// import { styleGuide } from './Style'
import __ from '../util'

class DashView extends React.Component {
  constructor (props) {
    super(props)
    this.cx = props.cx
    this.widthType = props.width
    this.reset = {
      loading: false,
      snack: undefined,
      url: '',
      frequency: 'once',
      jobs: []
    }
    this.state = {
      ...this.reset
    }
    this.postJob = this.postJob.bind(this)
    this.runJob = this.runJob.bind(this)
    this.delJob = this.delJob.bind(this)
    this.handleGoRun = (id) => {
      this.props.history.push('/runs/'+id)
    }
  }

  async postJob () {
    this.setState({ loading: true })
    try {
      await this.cx.dash.postJob({
        url: this.state.url,
        frequency: this.state.frequency,
        email: __.getJsonSto('core').loginemail //TODO validate
      })

      this.setState({snack: 'Job created'})

      await Promise.all([
        this.getJobs()
      ])
    } catch (e) {
      if (__.cfg('isDev')) throw e
      const emsg = e.message
      __.clearSto()
      this.setState({ loading: false, err: emsg })
      throw e
    }
  }


  async delJob (id) {
    this.setState({ loading: true })
    try {
      await this.cx.dash.delJob({
        url: this.state.jobs[id].url,
        email: __.getJsonSto('core').loginemail //TODO validate
      })

      this.setState({snack: 'Job deleted'})

      await Promise.all([
        this.getJobs()
      ])
    } catch (e) {
      if (__.cfg('isDev')) throw e
      const emsg = e.message
      __.clearSto()
      this.setState({ loading: false, err: emsg })
      throw e
    }
  }

  async runJob (id) {
    this.setState({ loading: true })
    try {
      await this.cx.dash.runJob({
        ...this.state.jobs[id]
      })
      this.setState({snack: 'Job started'})

      await Promise.all([
        this.getJobs()
      ])

    } catch (e) {
      if (__.cfg('isDev')) throw e
      const emsg = e.message
      __.clearSto()
      this.setState({ loading: false, err: emsg })
      throw e
    }
  }

  async getJobs () {
    this.setState({loading: true})
    try {
      const jobs = await this.cx.dash.getJobs({
        email: __.getJsonSto('core').loginemail //TODO validate
      })
      this.setState({jobs, loading: false})
      __.setJsonSto('codemonitor-jobs', jobs)
    } catch (e) {
      if (__.cfg('isDev')) throw e
      const emsg = e.message
      __.clearSto()
      this.setState({ loading: false, err: emsg })
      throw e
    }
  }

  async componentDidMount () {

    Object.assign(this, __.initView(this, 'dash'))

    await Promise.all([
     this.getJobs()
    ])
  }

  render () {
    if (this.state.err) {
      return (
        <ModalCust
          actions={[
            {
              lbl: 'Go to login',
              onClick: () => this.props.history.push('/login')
            }
          ]}
        >
          {this.state.err}
        </ModalCust>
      )
    } else if (this.state.loading) {
      return <LinearProgress />
    } else {
      return <div>
        {this.state.snack && (
          <Snack
            msg={this.state.snack}
            onClose={() => this.setState({ snack: undefined })}
          />
        )}
        <TopBar title noDash />
        {this.state.jobs && this.state.jobs.length > 0 && <Grid container spacing={1} justifyContent='center' style={{paddingTop: 20}}>
          {this.state.jobs.map((job, jobIndex) => (
            <Grid item xs={12} sm={8} md={6} lg={4} xl={4} key={jobIndex}>
              <Paper
              // className={this.props.classes.paperStyle}
              // elevation={10}
              style={{padding: 15, marginBottom: 20}}
              >
                <Typography variant='body1'>
                  Url: {job.url}
                </Typography>
                <Typography variant='body1'>
                  Status: {job.status}
                </Typography>
                <Typography variant='body1'>
                  Last run: {mo(job.lastRun, 'X').isValid() ? mo(job.lastRun, 'X').format() : ''}
                </Typography>
                <Typography variant='body1'>
                  Created: {mo(job.created, 'X').format()}
                </Typography>
                <Typography variant='body1'>
                  Runs: {job.runs}
                </Typography>
                <Typography variant='body1'>
                  Frequency: {job.frequency}
                </Typography>
                <Button
                  fullWidth
                  // variant='contained'
                  color='primary'
                  // className={this.props.classes.loginButton}
                  onClick={async event => this.handleGoRun(jobIndex)}
                  disabled={job.status !== 'done'}
                >
                  Show
                </Button>
                <Button
                  fullWidth
                  // variant='contained'
                  color='primary'
                  // className={this.props.classes.loginButton}
                  onClick={async event => this.runJob(jobIndex)}
                  disabled={job.status === 'running'}
                >
                  Run Again
                </Button>
                <Button
                  fullWidth
                  // variant='contained'
                  color='primary'
                  // className={this.props.classes.loginButton}
                  onClick={async event => this.delJob(jobIndex)}
                  style={{marginTop: 30}}
                >
                  Delete
                </Button>
              </Paper>
            </Grid>
            ))}
        </Grid>}
        <Grid container spacing={0} justifyContent='center' style={{paddingTop: 20}}>
          <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
            <Typography variant='h2' align='center'>
              New Job
              <PostAddIcon />
            </Typography>
            <Paper
              // className={this.props.classes.paperStyle}
              elevation={10}
              style={{padding: 15}}
            >
              <FormControl fullWidth>
                <InputLabel id="job-frequency-label">Frequency</InputLabel>
                <Select
                  labelId="job-frequency-label"
                  id="job-frequency"
                  value={this.state.frequency}
                  label="Frequency"
                  style={{marginBottom: 20}}
                  onChange={evt => this.setState({frequency: evt.target.value})}
                >
                  <MenuItem value={'once'}>Once</MenuItem>
                  <MenuItem value={'hourly'} disabled>Hourly</MenuItem>
                  <MenuItem value={'daily'} disabled>Daily</MenuItem>
                  <MenuItem value={'weekly'} disabled>Weekly</MenuItem>
                </Select>
              </FormControl>

              <TextField 
                fullWidth 
                id="job-url" 
                label="Url"
                variant="outlined" 
                placeholder="https://domain.com"
                style={{marginBottom: 20}}
                value={this.state.url}
                onChange={evt => this.setState({url: evt.target.value})}
              />

              <Button
                fullWidth
                variant='contained'
                color='primary'
                // className={this.props.classes.loginButton}
                onClick={async event => this.postJob(event)}
                //disabled={this.state.wpTitle.length === 0 || this.state.wpMainKw.length === 0 || this.state.wpSecKw.length === 0}
              >
                Create Job
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </div>
    }
  }
}

export default withStyles({
    // colorFormat
  })(DashView)
